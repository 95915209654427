
import { $ } from '../third-party-imports';
import { App } from './App';
import { startLoader } from './startLoader';
async function initLocationSearchMapPage()
{
  if($("#countryCode").val() === 'ca') {
    const mainWrapper = document.querySelector(".location-info-section");
    if (!mainWrapper)
      return;
    startLoader();
    //This is supposed to do the exact same thing as elavonClient.getMainWrapper(), but without loading the library first.
    // if(localStorage.getItem("isCityEntered") === "true"  || localStorage.getItem("isFSAEntered") === "true")  {
      $("#location-search-search-text-input").val(localStorage.getItem("locateCityname"));
      $("#location-search-btn").trigger('click');
    // }
    
    let app = new App();
    window.addEventListener('resize', () => {        
      app.windowResizeCallback();
    });
  }
}

document.addEventListener("DOMContentLoaded", function(){
  initLocationSearchMapPage();
})
